<div class="contain-lg">
  <form [formGroup]="karbejelentoKarosultOtthonForm" class="form">

    <ng-container *ngIf="toltoKereses">
      <div class="row">
        <div class="col-lg-12 bg-white">
          <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
          </ngx-skeleton-loader>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!toltoKereses">

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="mt-4">
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Név:</mat-label>
                  <mat-select matInput formControlName="f_biztositott" (selectionChange)="biztositottValtozas($event)">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let biztositott of biztositottakLista" [value]="biztositott.f_bizt_azon">{{biztositott.f_ugyfelnev}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Születési dátum:</mat-label>
                  <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_szul_datum" [max]="szulDatumMaxDate">
                  <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                  <mat-datepicker #szulDatumPicker></mat-datepicker>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_szul_datum').errors?.matDatepickerParse">
                    Hibás dátum!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Születési hely:</mat-label>
                  <mat-select matInput formControlName="f_szul_hely">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let telepules of szulTelepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Telefon típusa:</mat-label>
                  <mat-select matInput formControlName="f_telefon_tipus">
                    <mat-option value="M">Mobil</mat-option>
                    <mat-option value="V">Vezetékes</mat-option>
                    <mat-option value="K">Külföldi</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-lg-2" *ngIf="karbejelentoKarosultOtthonForm.get('f_telefon_tipus').value != 'K'">
                <mat-form-field appearance="fill">
                  <mat-label>Előhívó:</mat-label>
                  <mat-select matInput formControlName="f_telefon_elohivo" (selectionChange)="eloHivoValtozas()">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let eloHivo of eloHivoLista" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_telefon_elohivo').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Telefonszám:</mat-label>
                  <input type="text" matInput formControlName="f_telefon_szam" [textMask]="karbejelentoKarosultOtthonForm.get('f_telefon_tipus').value != 'K' ? {mask: phoneMask} : {mask: false}" [placeholder]="phonePlaceholder">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_telefon_szam').errors?.telefonszamUres">
                    Hiányzó adat!
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_telefon_szam').errors?.telefonszam">
                    Hibás adat!
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_telefon_szam').errors?.telefonszamKulfHossz">
                    Hibás adat! (Minimum 6 és maximum 20 karakterből állhat)
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_telefon_szam').errors?.telefonszamKulf">
                    Hibás adat! (Megengedett karakterek: 0-9, /, +, -)
                  </mat-error>
                </div>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>E-mail cím:</mat-label>
                  <input type="text" matInput formControlName="f_email" [textMask]="{mask: emailMask}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_email').errors?.pattern">
                    Hibás adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Fax:</mat-label>
                  <input type="text" matInput formControlName="f_fax" [textMask]="{mask: faxMask}" placeholder="+36(12) 123-1234">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_fax').errors?.fax">
                    Hibás adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <button mat-raised-button color="accent" [disabled]="!(karbejelentoKarosultOtthonForm.get('f_biztositott').valid && karbejelentoKarosultOtthonForm.get('f_telefon_elohivo').valid && karbejelentoKarosultOtthonForm.get('f_telefon_szam').valid && karbejelentoKarosultOtthonForm.get('f_fax').valid && karbejelentoKarosultOtthonForm.get('f_email').valid && karbejelentoKarosultOtthonForm.get('f_szul_datum').valid) || karbejelentoKarosultOtthonForm.get('f_biztositott').value == '0'" (click)="biztositottMentese()">Biztosított hozzáadása a károsulthoz</button>
              </div>

            </div>


            <div class="row mt-3">
              <div class="col-lg-4">
                <div class="font-info">
                  <span>Az alábbi károsult személy(ek) kerülnek rögzítésre a bejelentés során</span>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-4">
                <mat-table [dataSource]="dataSource">
                  <ng-container *ngFor="let column of karosultakOtthonTablaOszlopok" [matColumnDef]="column.columnDef">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]="column.class">
                      <div class="form-group w-100 mt-2 mb-2 pl-2">
                        <span [innerHTML]="column.header"></span>
                      </div>                      
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]="column.class">
                      <div *ngIf="column.adattipus != 'button'">
                        <span [innerHTML]="element.get([column.columnDef]).value | textTransform: column.numberFormat : column.adattipus"></span>
                      </div>
                      <div *ngIf="column.adattipus == 'button'">                        
                        <button mat-icon-button (click)="karosultTorlese(element);" matTooltip="Törlés">
                          <mat-icon class="material-icons-outlined">delete</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="getDisplayedOszlopok()"></mat-header-row>
                  <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedOszlopok();"></mat-row>
                </mat-table>                
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_table_ellenor').errors?.karosultDb">
                    Még nincs egy károsult se elmentve!
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKarosultOtthonForm.get('f_table_ellenor').errors?.karosultAzon">
                    A megadott károsult nem lett rögzítve!
                  </mat-error>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-container>
  </form>  
</div>
