<div class="contain-lg">
  
    <mat-card class="mat-elevation-z3">
      <mat-card-content>

        <div class="row mt-3">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <span *ngIf="azonosito != '0'">Kárbejelentő azonosító: {{azonosito}}</span>
            <div class="pt-2 font-bold font-info">
              <span>A csillaggal jelölt mezőket kötelező kitölteni</span>
            </div>

            <mat-vertical-stepper [linear]="true" #stepper (selectionChange)="onStepChange($event)">

              <mat-step [stepControl]="alapadatokFormGroup" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Alapadatok</span>
                  </div>
                </ng-template>
                <karbejelento-alapadatok #alapAdatok (karbejelentoAlapadatokFormOut)="alapadatokFormGroup=$event" (modozatOut)="modozat=$event"></karbejelento-alapadatok>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button mat-raised-button color="accent" matStepperNext [disabled]="!alapadatokFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="bejelentoFormGroup" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Bejelentő adatai</span>
                  </div>
                </ng-template>
                <karbejelento-bejelento (karbejelentoBejelentoFormOut)="bejelentoFormGroup=$event"></karbejelento-bejelento>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!bejelentoFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="karesemenyFormGroup" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Káresemény adatai</span>
                  </div>
                </ng-template>
                <karbejelento-karesemeny (karbejelentoKaresemenyFormOut)="karesemenyFormGroup=$event"></karbejelento-karesemeny>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!karesemenyFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="karosultFormGroup" *ngIf="isKarosultSzemely" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>{{karosultLabel}}</span>
                  </div>
                </ng-template>
                <karbejelento-karosult (karbejelentoKarosultFormOut)="karosultFormGroup=$event"></karbejelento-karosult>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!karosultFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="karosultOtthonFormGroup" *ngIf="isKarosultSzemelyOtthon" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Károsult személy adatai</span>
                  </div>
                </ng-template>
                <karbejelento-karosult-otthon (karbejelentoKarosultOtthonFormOut)="karosultOtthonFormGroup=$event"></karbejelento-karosult-otthon>

                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!karosultOtthonFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="szemleFormGroup" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Szemle adatai</span>
                  </div>
                </ng-template>
                <karbejelento-szemle (karbejelentoSzemleFormOut)="szemleFormGroup=$event"></karbejelento-szemle>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!szemleFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="gepjarmuSzemelyFormGroup" *ngIf="isKarosultGepjarmu" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Károsult gépjármű személy(ek) adatai</span>
                  </div>
                </ng-template>
                <karbejelento-gepjarmu-szemely (karbejelentoGepjarmuSzemelyFormOut)="gepjarmuSzemelyFormGroup=$event"></karbejelento-gepjarmu-szemely>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!gepjarmuSzemelyFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="karosultVagyontargyFormGroup" *ngIf="isKgfbVagyon" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Károsult vagyontárgy</span>
                  </div>
                </ng-template>
                <karbejelento-vagyontargy (karbejelentoVagyontargyFormOut)="karosultVagyontargyFormGroup=$event"></karbejelento-vagyontargy>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!karosultVagyontargyFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="fajlFeltoltesFormGroup" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-template matStepLabel>
                  <div class="text-center">
                    <span>Fájl feltöltése</span>
                  </div>
                </ng-template>
                <karbejelento-fajlfeltoltes (karbejelentoFajlFeltoltesFormOut)="fajlFeltoltesFormGroup=$event"></karbejelento-fajlfeltoltes>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <button class="mr-5" mat-raised-button color="accent" matStepperPrevious>Előző</button>
                    <button class="ml-5" mat-raised-button color="accent" matStepperNext [disabled]="!fajlFeltoltesFormGroup.valid">Következő</button>
                  </div>
                </div>
              </mat-step>

              <mat-step [stepControl]="eredmenyFormGroup" [editable]="stepFormGroup && !karbejelentesVege">
                <ng-container *ngIf="toltoKereses">
                  <div class="row">
                    <div class="col-lg-12 bg-white">
                      <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!toltoKereses">
                  <ng-template matStepLabel>
                    <div class="text-center">
                      <span>Adatok beküldése</span>
                    </div>
                  </ng-template>
                  <karbejelento-eredmeny (karbejelentoEredmenyFormOut)="eredmenyFormGroup=$event"></karbejelento-eredmeny>
                  <div class="row mt-5">
                    <div class="col-lg-4">
                      <button class="mr-5" mat-raised-button color="accent" matStepperPrevious [disabled]="karbejelentesVege">Előző</button>
                      <button class="ml-5" mat-raised-button color="accent" (click)="adatokBekuldese()" [disabled]="!eredmenyFormGroup.valid || karbejelentesVege">Adatok beküldése</button>
                    </div>
                  </div>
                </ng-container>
              </mat-step>

            </mat-vertical-stepper>
            <div class="pt-2">
              <mat-error *ngIf="mentes_uzenet != ''">
                {{mentes_uzenet}}
              </mat-error>
            </div>

          </div>
        </div>

      </mat-card-content>
    </mat-card>
</div>
