import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { GranitTableComponent, UtilityService } from '@granit/common-library';
import * as moment from 'moment';
import emailMask from 'text-mask-addons/dist/emailMask';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'karbejelento-karosult-otthon',
  templateUrl: './karbejelento-karosult-otthon.component.html',
  styleUrls: ['./karbejelento-karosult-otthon.component.scss']
})
export class KarbejelentoKarosultOtthonComponent implements OnInit {

  @Output() karbejelentoKarosultOtthonFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoKarosultOtthonForm: FormGroup;

  biztositottakLista: any[];
  szulTelepulesLista: any[];
  eloHivoLista: any[];
  szulDatumMaxDate: string = moment(new Date()).add('years', -14).format('YYYY-MM-DD');
  toltoKereses: boolean = false;

  phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholder = "123-1234";
  emailMask = emailMask;
  faxMask = ['+', '3', '6', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  karosultakOtthonTablaOszlopok: any[] = [
    { columnDef: 'f_nev', header: 'Név' },
    { columnDef: 'f_szuldat', header: 'Szüldat' },
    { columnDef: 'f_szulhely', header: 'Szülhely' },
    { columnDef: 'f_azonosito', visible: false },
    { columnDef: 'gomb', adattipus: 'button', class: 'col-1' }
  ];

  getDisplayedOszlopok(): string[] {
    let displayedColumns = this.karosultakOtthonTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  dataSource: MatTableDataSource<any>;

  constructor(private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.formGroupNew();
    this.telefonElohivoFeltoltes();
    this.szulTelepulesFeltoltes();

    this.karbejelentoKarosultOtthonFormOut.emit(this.karbejelentoKarosultOtthonForm);

    this.karbejelentoKarosultOtthonForm.controls['f_telefon_tipus'].valueChanges.subscribe(value => {
      this.telefonElohivoFeltoltes();
    });
    
    this.karbejelentoKarosultOtthonForm.controls['f_szerzazon'].valueChanges.subscribe(value => {      
        this.biztositottakFeltoltes();
    });

    this.dataSource = new MatTableDataSource<any>();    
    
  }

  

  formGroupNew() {
    this.karbejelentoKarosultOtthonForm = this.formBuilder.group({
      f_azonosito: new FormControl(null),
      f_szerzazon: new FormControl(null),
      f_biztositott: new FormControl(null),
      f_karosult_tipusa: new FormControl("b"),
      f_nev: new FormControl(null),
      f_szul_datum: new FormControl(null),
      f_szul_hely: new FormControl("0"),
      f_telefon_tipus: new FormControl("M"),
      f_telefon_elohivo: new FormControl("0"),
      f_telefon_szam: new FormControl(null),
      f_email: new FormControl(null),
      f_fax: new FormControl(null),
      f_table_ellenor: new FormControl(null),
      mentettKarosultAzon: new FormControl(null),
      adatok: new FormControl(null)
    }, {
      validators: [AdatValidator.fuggosegiKotelezettseg('f_telefon_elohivo', 'f_biztositott', ''),
      AdatValidator.telefonszam('f_telefon_szam', 'f_telefon_tipus', 'f_telefon_elohivo', 'f_biztositott'),
      AdatValidator.telefonszam('f_fax', '', '', 'f_biztositott')]
    });
  }

  biztositottakFeltoltes() {
    this.toltoKereses = true;
    this.biztositottakLista = [];
    
    this.karbejelentoKarosultOtthonForm.controls["f_biztositott"].setValue("0");
    this.karbejelentoKarosultOtthonForm.controls["f_szul_datum"].setValue(null);
    this.karbejelentoKarosultOtthonForm.controls["f_szul_hely"].setValue("0");
    this.karbejelentoKarosultOtthonForm.controls["f_nev"].setValue(null);
    this.karbejelentoKarosultOtthonForm.controls["f_telefon_tipus"].setValue("M");
    this.karbejelentoKarosultOtthonForm.controls["f_telefon_elohivo"].setValue("0");
    this.karbejelentoKarosultOtthonForm.controls["f_telefon_szam"].setValue(null);
    this.karbejelentoKarosultOtthonForm.controls["f_email"].setValue(null);
    this.karbejelentoKarosultOtthonForm.controls["f_fax"].setValue(null);
    this.karbejelentoKarosultOtthonForm.controls["f_table_ellenor"].setValue(null);
    this.karbejelentoKarosultOtthonForm.controls["mentettKarosultAzon"].setValue(null);

    let model = {
      f_szerzazon: this.karbejelentoKarosultOtthonForm.controls["f_szerzazon"].value,
      f_azonosito: this.karbejelentoKarosultOtthonForm.controls["f_azonosito"].value
    }

    this.karbejelento.otthonBiztositottak(model).subscribe(
      data => {

        const fgs = data["spMentettBiztositottak"].map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        this.dataSource.data = this.formBuilder.array(fgs).controls;

        this.biztositottakLista = data["spValaszthatoBiztositottak"];

        this.karbejelentoKarosultOtthonForm.controls["mentettKarosultAzon"].setValue(data["spKaralap"].f_karosult_otthon_azon);
        this.karbejelentoKarosultOtthonForm.controls["adatok"].setValue(this.formBuilder.array(this.dataSource.data).value);

        this.karosultEllenor();

        this.toltoKereses = false;

      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a biztosítottak adatainak lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
        this.toltoKereses = false;
      });
  }

  szulTelepulesFeltoltes() {
    this.szulTelepulesLista = [];
    this.karbejelentoKarosultOtthonForm.controls["f_szul_hely"].setValue("0");
    this.karbejelento.osszTelepules().subscribe(
      data => {
        this.szulTelepulesLista = data;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a települések lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  telefonBeallitas() {
    this.karbejelentoKarosultOtthonForm.controls["f_telefon_szam"].setValue(null);
    if (this.karbejelentoKarosultOtthonForm.controls["f_telefon_tipus"].value == "M") {
      this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholder = "123-1234";
    }
    if (this.karbejelentoKarosultOtthonForm.controls["f_telefon_tipus"].value == "V") {
      if (this.karbejelentoKarosultOtthonForm.controls["f_telefon_elohivo"].value == "01") {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-1234";
      }
      else {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-123";
      }
    }
    if (this.karbejelentoKarosultOtthonForm.controls["f_telefon_tipus"].value == "K") {
      this.phonePlaceholder = "";
    }
  }

  telefonElohivoFeltoltes() {
    this.telefonBeallitas();
    this.eloHivoLista = [];
    this.karbejelentoKarosultOtthonForm.controls["f_telefon_elohivo"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoKarosultOtthonForm.controls["f_telefon_tipus"].value).subscribe(
      data => {
        this.eloHivoLista = data;
      })
  }

  eloHivoValtozas() {
    this.telefonBeallitas();
  }

  biztositottValtozas(event: any) {
    if (event.value != "0") {
      let adatok = this.biztositottakLista.find(x => x.f_bizt_azon == event.value)
      this.karbejelentoKarosultOtthonForm.controls["f_szul_datum"].setValue(adatok.f_szuldat);
      this.karbejelentoKarosultOtthonForm.controls["f_szul_hely"].setValue(adatok.f_szulhely.toUpperCase());
      this.karbejelentoKarosultOtthonForm.controls["f_nev"].setValue(adatok.f_ugyfelnev);
    }
    else {
      this.karbejelentoKarosultOtthonForm.controls["f_szul_datum"].setValue(null);
      this.karbejelentoKarosultOtthonForm.controls["f_szul_hely"].setValue("0");
      this.karbejelentoKarosultOtthonForm.controls["f_nev"].setValue(null);
    }
  }

  biztositottMentese() {
    this.karbejelento.karosultOtthonMentes(this.karbejelentoKarosultOtthonForm.value).subscribe(
      data => {
        if (data["f_uzenet"] == "") {
          this.utilityService.dialogMegnyitasa({
            success: true,
            title: 'Siker',
            message: "A mentés sikeresen megtörtént.",
            confirmButtonText: 'Rendben'
          });
          this.biztositottakFeltoltes();
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: data["f_uzenet"],
            confirmButtonText: 'Rendben'
          });
        }
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a károsult mentése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  karosultTorlese(element: FormGroup) {

    let model = {
      f_karosult_azonosito: element.get(['f_karosult_azonosito']).value
    }

    this.karbejelento.karosultOtthonTorles(model).subscribe(
      () => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Siker',
          message: "A törlés sikeresen megtörtént.",
          confirmButtonText: 'Rendben'
        });
        this.biztositottakFeltoltes();
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a károsult törlése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  karosultEllenor() {
    this.karbejelentoKarosultOtthonForm.controls["f_table_ellenor"].setErrors(null)
    if (this.dataSource.data && this.karbejelentoKarosultOtthonForm) {
      if (this.dataSource.data.length == 0) {
        this.karbejelentoKarosultOtthonForm.controls["f_table_ellenor"].setErrors({ karosultDb: true })
      }
      else {
        if (this.dataSource.data.findIndex(x => x.controls["f_karosult_azonosito"].value == this.karbejelentoKarosultOtthonForm.controls['mentettKarosultAzon'].value) < 0) {
          this.karbejelentoKarosultOtthonForm.controls["f_table_ellenor"].setErrors({ karosultAzon: true });
        }
      }
    }
  }

}

export class AdatValidator extends Validators {

  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      control1.setErrors(null);

      if (control2_neve == "f_telefon_tipus" && control2.value != "K" && (control1.value == null || control1.value == '0')) {
        hasError = true;
      }

      if (control2.value != "0" && control1_neve == "f_telefon_elohivo" && (control1.value == null || control1.value == '0')) {
        hasError = true;
      }


      if (hasError) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

    }
    return validator;
  }

  static telefonszam(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      control1.setErrors(null);

      if (control4.value != "0") {

        if (control1_neve == "f_fax" && control1.value) {

          if (control1.value.split("_").join("").length < 15) {
            control1.setErrors({ fax: true });
          }

        }

        if (control1_neve == "f_telefon_szam") {
          if (control1.value == null || control1.value == "") {
            control1.setErrors({ telefonszamUres: true });
          }
          else {


            if (control2.value != "K") {

              if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
                if (control1.value.split("_").join("").split("-").join("").length != 7) {
                  control1.setErrors({ telefonszam: true });
                }
              }

              if (control2.value == "V" && control3.value != "01") {
                if (control1.value.split("_").join("").split("-").join("").length != 6) {
                  control1.setErrors({ telefonszam: true });
                }
              }
            }
            else {
              if (control1.value.split("_").join("").length < 6 || control1.value.split("_").join("").length > 20) {
                control1.setErrors({ telefonszamKulfHossz: true });
              }
              else {

                if (control1.value.match("^[0-9/+-]+$") == null) {
                  control1.setErrors({ telefonszamKulf: true });
                }
              }
            }
          }
        }
      }
    }
    return validator;
  }

}

